import React from "react";
import { Wrapper,
        ButtonWrapper } 
        from "./Modal.styled";
import { useTranslation } from "react-i18next";


const UserSelector = () => {

  const {t} = useTranslation();

  const handleButtonClick1 = () => {
  };
  const handleButtonClick2 = () => {
  };

  return (
    <Wrapper id="user-modal">
      <ul>
        <li>
            <ButtonWrapper
            onClick={handleButtonClick1}>
            <button>
                <p>{t("modal_windows.user.log_in")}</p>
            </button>
            </ButtonWrapper>
        </li>
        <li>
            <ButtonWrapper>
                <button 
                onClick={handleButtonClick2}>
                    <p>{t("modal_windows.user.registration")}</p>
                </button>
            </ButtonWrapper>
        </li>
      </ul>
    </Wrapper>
  );
};

export default UserSelector;
