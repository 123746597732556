import React from "react";
import { Wrapper,
        ButtonWrapper } 
        from "./Modal.styled";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getLocationData, getWeatherData } from "./../../store"
import { v4 as uuidv4 } from 'uuid';

const LocationSelector = () => {
  const { locations } = useSelector(
    (state) => state.locationReducer
  );
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getLocationData());
  }, [dispatch]);

  const handleButtonClick1 = (loc_id, display_name) => {
    dispatch(getWeatherData({ location_id: loc_id }));
    document.getElementById("current-location").innerText = display_name;
  };

  return (
    <Wrapper>
      <ul>
        {locations.map((loc) => (
          <li key={uuidv4()}>
            <ButtonWrapper>
              <button onClick={() => {handleButtonClick1(loc.id, loc.display_name); }}>
                <p>{loc.display_name}</p>
              </button>
            </ButtonWrapper>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default LocationSelector;
