import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";


import store from "./store/store.config";
import App from './App';
import './index.css';

import "./18n"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>
        <Suspense fallback={<div>loading</div>}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
        </Suspense>
    </Provider>
);

