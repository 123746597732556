import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 140px; 
  align-items: center; 
  justify-content: space-around;
  gap:40%;


  .location-nav {
  width: 150px;
  height: 36px;
  border: 3px solid white;
  border-radius: 10px;
  transition: none;

  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: transparent;
  color: white;
  font-size: 20px;
  font-weight: 500;

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(15px);

  @media(max-width:480px){
    width: 95px;
    font-size: 14px;
  }

  
}

@media(min-width:480px) and (max-width:700px){
  gap:25%;
}

@media(max-width:480px){
  gap:10%;
}
  
`;

export const ButtonWrapper = styled.div`
  display: flex;

  @media(max-width:480px){
    margin-right: 0px;
  }
`;

export const UserButtonWrapper = styled.div`
  margin-right: 36px;
  @media(max-width:480px){
    margin-right: 10px;
  }
`;

export const LanguageButtonWrapper = styled.div``;

export const LocationButtonWrapper = styled.div`
  margin-right: 36px;
  @media(max-width:480px){
    margin-right: 10px;
  }
`;

