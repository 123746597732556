import "./normalize.css";
import "./App.css";
import Layout from "./components/Layout/Layout";
import Navigation from "./components/Layout/Navigation/Navigation";

import bg3 from "./images/bg3.jpg";

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${bg3})` }}>
      <Navigation />
      <Layout />
      <p style={{ color: "rgba(255, 255, 255, 0.60)", fontSize: "18px" }}>
        Photographer site - <a href="https://grigoryev.net">Олег Григор’єв</a>
      </p>
    </div>
  );
}

export default App;
