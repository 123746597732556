import { useDispatch, useSelector,} from "react-redux";
import { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import { getWeatherData } from "./../../store";
import Forecast from "./Forecast/Forecast";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  TableWrapper,
  ForecastLineWrapper,
  Line,
  InformationWrapper,
  FlagWrapper,
  LongLine,
  BadWeatherWrapper,
  GoodWeatherWrapper,
  WeatherWrapper,
  TitleWrapper,
  RepairWrapper
} from "./Layout.styled";
import red_flag from "./../../images/icons/red_flag.svg";
import green_flag from "./../../images/icons/green_flag.svg";
import repair_logo from "./../../images/icons/repair_logo.png";

import humidity_icon from "./../../images/icons/humidity_icon.svg";
import temperature_icon from "./../../images/icons/temperature_icon.svg";
import time_icon from "./../../images/icons/time_icon.svg";
import wind_speed_icon from "./../../images/icons/wind_speed_icon.svg";
import wind_direction_icon from "./../../images/icons/wind_direction_icon.svg";



const Layout = () => {
  const { forecasts, status } = useSelector((state) => state.weatherReducer);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getWeatherData({"location_id": 1}));
  }, [dispatch]);
  
  return (
    <Wrapper>
      {status === "fulfilled" && forecasts && forecasts.length === 0 &&<RepairWrapper><img src={repair_logo} alt="repair_logo"></img></RepairWrapper>}
      {status === "pending" && <div>loading</div>}
      {status === "fulfilled" && forecasts && forecasts.length !== 0 &&(
        <>
          <FlagWrapper>
            {forecasts[0]["wind_speed"] < 3 && (
              <WeatherWrapper>
                <img src={red_flag} alt="flag"></img>
                <BadWeatherWrapper>
                  <p>{t("table.flag.red_flag_less")}</p>
                </BadWeatherWrapper>
              </WeatherWrapper>
            )}

            {forecasts[0]["wind_speed"] >= 3 &&
              forecasts[0]["wind_speed"] <= 6 && (
                <WeatherWrapper>
                  <img src={green_flag} alt="flag"></img>
                  <GoodWeatherWrapper>
                    <p>{t("table.flag.green_flag")}</p>
                  </GoodWeatherWrapper>
                </WeatherWrapper>
              )}

            {forecasts[0]["wind_speed"] > 6 && (
              <WeatherWrapper>
                <img src={red_flag} alt="flag"></img>
                <BadWeatherWrapper>
                  <p>{t("table.flag.red_flag_more")}</p>
                </BadWeatherWrapper>
              </WeatherWrapper>
            )}
          </FlagWrapper>
          <LongLine></LongLine>
          <TableWrapper>
            {status === "fulfilled" && (

              
              <ul>
                <TitleWrapper>
                  <li id="desktop-title">
                    <p>{t("table.title.Temperature_column")}</p>
                    <p>{t("table.title.Humidity_column")}</p>
                    <p>{t("table.title.Wind_speed_column")}</p>
                    <p>{t("table.title.Wind_direction_column")}</p>
                    <p>{t("table.title.Precipitation_column")}</p>
                    <p>{t("table.title.Date_and_time_column")}</p>
                    <p>{t("table.title.Battery_charge_column")}</p>                 
                  </li>

                  <li id="mobile-title">
                    <div><img src={temperature_icon} alt="temperature_icon"></img></div>
                    <div><img src={humidity_icon} alt="humidity_icon"></img></div>
                    <div><img src={wind_speed_icon} alt="wind_speed_icon"></img></div>
                    <div><img src={wind_direction_icon} alt="wind_direction_icon"></img></div>
                    <div><img src={time_icon} alt="time_icon"></img></div>
                  </li>
                </TitleWrapper>
                {forecasts.map((f) => (
                  <div key={uuidv4()}>
                    <ForecastLineWrapper>
                      <Line></Line>
                      <InformationWrapper>
                        <Forecast key={f.timestamp} forecast={f} />
                      </InformationWrapper>
                    </ForecastLineWrapper>
                  </div>
                ))}
              </ul>
            )}
          </TableWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default Layout;
