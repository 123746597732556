import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
`;

class ImageButton extends React.Component {
  render() {
    const { imagePath, altText, width, height, onClickHandler, border, fill } = this.props;

    return (
      <StyledButton onClick={onClickHandler} style={{ border: border }}>
        <img
          src={imagePath}
          alt={altText}
          style={{ width: width, height: height, fill: fill }}
        />
      </StyledButton>
    );
  }
}

export default ImageButton;