import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationData } from "./../../../store"

import {
  Wrapper,
  UserButtonWrapper,
  LanguageButtonWrapper,
  ButtonWrapper,
  LocationButtonWrapper,
} from "./Navigation.styled";

import ImageButton from "../../GlobalComponents/ImageButton";
import userButtonImg from "../../../images/icons/user_icon.svg";
import languagesButtonImg from "../../../images/icons/language.svg";
import location from "../../../images/icons/location.svg"
import LanguageSelector from "../../GlobalComponents/LanguageModal";
import LocationSelector from "../../GlobalComponents/LocationModal";
import UserSelector from "../../GlobalComponents/UserModal";
import smallLogo from "../../../images/icons/smallLogo.svg"



const Navigation = () => {
  const [isLanguageModalVisible, setLanguageModalVisible] = useState(false);
  const [isLocationModalVisible, setLocationModalVisible] = useState(false);
  const [isUserModalVisible, setUserModalVisible] = useState(false);

  const handleButtonClick1 = () => {
    setLocationModalVisible(!isLocationModalVisible);
    setLanguageModalVisible(false);
    setUserModalVisible(false);
  };

  const handleButtonClick2 = () => {
    setUserModalVisible(!isUserModalVisible);
    setLocationModalVisible(false);
    setLanguageModalVisible(false);
  };

  const handleButtonClick3 = () => {
    setLanguageModalVisible(!isLanguageModalVisible);
    setLocationModalVisible(false);
    setUserModalVisible(false);
  };

  const { locations } = useSelector(
    (state) => state.locationReducer
  );
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getLocationData());
  }, [dispatch]);

  return (
    <Wrapper>
        <div>
        <img src={smallLogo} alt="smallLogo"/>
        </div>
      <ButtonWrapper>
        <LocationButtonWrapper>
          <button className="location-nav" onClick={handleButtonClick1}>
            <p id="current-location">{locations[0]?.display_name}</p>
          </button>
        </LocationButtonWrapper>
        {/*<UserButtonWrapper>
          <ImageButton
            imagePath={userButtonImg}
            altText="User button img"
            width="35px"
            height="35px"
            onClickHandler={handleButtonClick2}
            border="0px"
            fill="none"
          />
        </UserButtonWrapper>
        */}
        <LanguageButtonWrapper>
          <ImageButton id="dkkd"
            imagePath={languagesButtonImg}
            altText="Languages button img"
            width="35px"
            height="35px"
            onClickHandler={handleButtonClick3}
            border="0px"
          />
        </LanguageButtonWrapper>
      </ButtonWrapper>

      {isLanguageModalVisible && <LanguageSelector />}
      {isLocationModalVisible && <LocationSelector />}
      {isUserModalVisible && <UserSelector />}
    </Wrapper>
  );
};
export default Navigation;
