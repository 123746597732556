import styled from "styled-components";

export const Wrapper = styled.div`
  width: 300px;
  min-height: 150px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  position: absolute;
  top: 100px;
  right: 8%;
  z-index: 3;

  li {
    list-style-type: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    margin: 20px 0;
  }

  @media(max-width:360px){
    gap:20%

  

  }
`;

export const ButtonWrapper = styled.div`
  button {
    width: 200px;
    height: 40px;
    border: 0px;
    padding: 0;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    color: #3b3b3b;
    transition: background-color 0.3s ease, color 0.3s ease;
    -webkit-background-color: white;

    p {
      font-family: Inter;
      @media(max-width:360px){
        font-size:15px
      }
    }

    &:focus {
      backdrop-filter: rgba(35, 168, 89, 0.5);
      -webkit-backdrop-filter: rgba(35, 168, 89, 0.5);
      background: rgba(35, 168, 89, 0.5);
      color: white;
    }

    &:active {
      backdrop-filter: rgba(35, 168, 89, 0.5);

      -webkit-backdrop-filter:rgba(35, 168, 89, 0.5);
      background: rgba(35, 168, 89, 0.5);
      color: white;
    }

    @media(max-width:360px){
      width: 137px;
      height: 37px;
      top: 80px;
    }

  }
`;

