import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {weatherService} from "../services";


export const getLocationData = createAsyncThunk(
    'locationSlice/getLocationData',
    async (arg)=>{
        try {
            return await weatherService.getLocations();
        }
        catch (e) {
            console.log(e)
        }
    }
)


const locationSlice = createSlice( {
    name:'locationSlice',
    initialState: {
        current_location_id: 1,
        locations:[],
        status: null,
        error: null
    },
    reducers:{
        SetCurrentLocation:(state, action) => {
            state.current_location_id = action.payload;
        }
    },
    extraReducers: {
        [getLocationData.pending]: (state, action) =>{
            state.status = 'pending'
            state.error = null
        },
        [getLocationData.fulfilled]: (state, action) =>{
            state.status = 'fulfilled'
            state.locations = action.payload
        },
        [getLocationData.rejected]: (state, action) =>{
    
        }
    }
})



const locationReducer = locationSlice.reducer

export const {SetCurrentLocation} = locationSlice.actions

export default locationReducer