import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;

  width: 96%;
  margin-top: 20px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter:blur(15px);
  z-index: 1;

  border-radius: 30px;
  margin-bottom: 20px;

`;

export const RepairWrapper = styled.div`
height: 500px;
width: 500px;
display: flex;
align-self: center;
justify-content: center;
align-items: center;


img{
  height: 400px;
  width: 400px;

}

`;

export const TableWrapper = styled.div`
  align-self: center;

  z-index: 2;

  li {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    color: white;

    display: grid;
    grid-template-columns: repeat(5, 1fr [col-center]) 2fr 1fr;
    column-gap: 40px;
  }
  ul {
    width: 90vw;
    padding: 0 20px 0 20px;

  }

  @media (max-width: 1450px) {
    li {
      grid-template-columns: repeat(3, 1fr [col-center]) 2fr 1fr 1fr;
    }
  }

  @media (max-width: 1250px) {
    li {
      grid-template-columns: repeat(5, 1fr [col-center]);


    }
  }

  @media (max-width: 680px) {
    li {
      grid-template-columns: repeat(4, 2fr [col-center]) 3fr;
      font-size: 18px;
      column-gap: 30px;
    }
  }

  @media (max-width: 520px) {
    li {
      font-size: 17px;
      column-gap: 25px;
    }
  }

  @media (max-width: 520px) {
    li {
      font-size: 16px;
      column-gap: 20px;
    }
  }

  @media (max-width: 475px) {
    li {
      font-size: 16px;
      column-gap: 10px;
    }
  }

  @media (max-width: 350px) {
    li {
      column-gap: 7px;
    }
  }

  @media (max-width: 330px) {
    li {
      font-size: 15px;
      column-gap: 5px;
    }
  }
  
  @media (max-width: 305px) {
    li {
      font-size: 14px;
      column-gap: 3px;
    }
  }

`;

export const TitleWrapper = styled.div`
  margin-top: 10px ;
  margin-bottom: 10px;
  align-items: center;

  li:nth-child(2) {
      display: none;
    }

  @media (max-width: 1450px) {
    li > p:nth-child(5) {
      display: none;
    }
  }

  @media (max-width: 1250px) {
    li > p:nth-child(7) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    li:nth-child(1) {
      display: none;
    }

    li:nth-child(2) {
      display: grid;
    }
  }

  /* @media (max-width: 630px) {
    li > div {
      font-size: 18;
    }
  } */

`;


export const ForecastLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  height: 0.5px;
  background: #b3b3b3;
`;

export const InformationWrapper = styled.div`
  justify-content: center;
`;

export const FlagWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 96px;

  p {
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.48px;
  }

  /* @media (max-width: 620px) {
    p {
      font-size: 22px;
    }
  } */

  @media (max-width: 570px) {
    p {
      font-size: 22px;
    }
  }

  @media (max-width: 530px) {
    p {
      font-size: 20px;
    }
  }

  @media (max-width: 490px) {
    p {
      font-size: 18px;
    }
  }

  @media (max-width: 370px) {
    p {
      font-size: 16px;
    }
  }

  @media (max-width: 340px) {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 275px) {
    p {
      font-size: 12px;
    }
  }

`;

export const BadWeatherWrapper = styled.div`
  color: white;
`;

export const GoodWeatherWrapper = styled.div`
  color: white;
`;


export const WeatherWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  gap: 20px;
  
  img {
    height: 63px;
    margin-right: 20px;
    
  }
`;


export const LongLine = styled.div`
  width: 98%;
  height: 0.5px;
  background: #b3b3b3;
  align-self: center;
`
