import React from "react";
import USA_flag from "../../images/icons/usa_flag.svg";
import Ukraine_flag from "../../images/icons/ukraine_flag.svg";
import { Wrapper, ButtonWrapper } from "./Modal.styled";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Wrapper id="language-modal">
      <ul>
        <li>
          <ButtonWrapper>
            <button onClick={() => changeLanguage("ukr")}>
              <img src={Ukraine_flag} alt="Ukraine_flag"></img>
              <p>Українська</p>
            </button>
          </ButtonWrapper>
        </li>
        <li>
          <ButtonWrapper>
            <button onClick={() => changeLanguage("en")}>
              <img src={USA_flag} alt="USA_flag"></img>
              <p>English</p>
            </button>
          </ButtonWrapper>
        </li>
      </ul>
    </Wrapper>
  );
};

export default LanguageSelector;
