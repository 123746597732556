import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {weatherService} from "../services";


export const getWeatherData = createAsyncThunk(
    'weatherSlice/getWeatherData',
    async (arg)=>{
        try {
            return await weatherService.getWeatherData(arg["location_id"]);
        }
        catch (e) {
            console.log(e)
        }
    }
)


const weatherSlice = createSlice( {
    name:'weatherSlice',
    initialState: {
        forecasts:[],
        status: null,
        error: null
    },
    reducers:{},
    extraReducers: {
        [getWeatherData.pending]: (state, action) =>{
            state.status = 'pending'
            state.error = null
        },
        [getWeatherData.fulfilled]: (state, action) =>{
            state.status = 'fulfilled'
            state.forecasts = action.payload
        }
    }
})



const weatherReducer = weatherSlice.reducer

export default weatherReducer