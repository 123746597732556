import styled from "styled-components";

export const ForecastLineWrapper = styled.div`
  > li {
    display: grid;
    grid-template-columns: repeat(5, 1fr [col-center]) 2fr 1fr;
    column-gap: 40px;
    align-items: center;
    height: 60px;

    > p {
      color: #fffbfb;
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.36px;
    }
  }

  @media (max-width: 1450px) {

    & > li {
      grid-template-columns: repeat(3, 1fr [col-center]) 2fr 1fr 1fr;
    }
    & > li > div:nth-child(5) {
      display: none;
    }
  }

  @media (max-width: 1250px) {

    & > li {
      grid-template-columns: repeat(5, 1fr [col-center]);
    }
    & > li > div:nth-child(7) {
      display: none;
    }
  }

`;

export const BatteryWrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    z-index: 0;
  }
  > div {
    z-index: 1;
    position: absolute;
    margin-right: 6px;
  }
`;
