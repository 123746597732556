import { useTranslation } from 'react-i18next';
import {
  ForecastLineWrapper,
  BatteryWrapper,
} from "../Forecast/Forecast.styled";

import green_battery from "../../../images/icons/green_battery.svg";
import red_battery from "../../../images/icons/red_battery.svg";
import arrow from "../../../images/icons/arrow.svg";

const Forecast = ({

  forecast: {
    humidity,
    precipitation,
    temperature,
    timestamp,
    wind_direction,
    wind_speed,
    battery_level,
  },
}) => {

  console.log(timestamp)

  const { t } = useTranslation();
  let timestr = "---";

  const current = new Date();
  let date = current.getDate();
  console.log(date)
  let gdate = Number(timestamp.slice(8, 10))
  console.log(gdate)
  let time = timestamp.slice(10)


  if (date - gdate === 0) {
    timestr = `Сьогодні`
  }
  else if (date - gdate === 1) {
    timestr = `Вчора`
  }
  else {
    time = "";
  }
  
  return (
    
      <ForecastLineWrapper>
        <li>

          <div>{temperature} °C</div>
          <div>{humidity} %</div>
          <div>{wind_speed} {t('table.title.wind_speed_measure')}</div>
          <div>
            <img
              src={arrow}
              style={{ transform: `rotate(${wind_direction}deg)` }}
              alt="arrow"
            ></img>
          </div>
          <div>{precipitation}</div>
          <div>{timestr}<br/>{time}</div>
          <div>
            <BatteryWrapper>
              {battery_level<=20&&<img src={red_battery} alt="red_battery"></img>}
              {battery_level>20&&<img src={green_battery} alt="green_battery"></img>}
              <div>{battery_level}%</div>
            </BatteryWrapper>
          </div>
        </li>
      </ForecastLineWrapper>
  );
};

export default Forecast;
